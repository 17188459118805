@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 { @apply text-3xl text-gray-900 dark:text-gray-200; }
  h2 { @apply text-3xl text-gray-900 dark:text-gray-200; }
  h3 { @apply text-2xl text-gray-800 dark:text-gray-300; }
  h4 { @apply text-xl text-gray-800 dark:text-gray-300; }
  h5 { @apply text-xl text-gray-700 dark:text-gray-400; }
  p, span { @apply text-sm text-gray-700 dark:text-gray-400; }

  label { @apply text-gray-600 dark:text-gray-400; }
  
  pre {
    @apply p-8 m-2 border border-black/5 text-red-500 whitespace-pre-wrap break-words dark:border-white/10 dark:text-pink-500;
  }

  li, ul, ol, select, strong {
    @apply text-gray-800 dark:text-gray-200 px-2;
  }
}
